<template>
  <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh"></data-view>
</template>
<script>
import dataView from '@/components/Common/DataView.vue'

export default {
  name: "reportList",
  props: [],
  components: {
    dataView
  },
  data() {
    return {
      op_name: this.$options.name,
      updateCount:0,
      columnsFormat: [
        /*{
          title: "所属城市",
          key: "city",
          align: "center",
          width: 90
        },*/
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 90,
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_code;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "安评时间",
          render: (h, p) => {
            return h(
              "span",
              {
                attrs: {}
              },
              p.row.evaluate_time.split("-")[0]
            );
          },
          sortable: true,
          width: 120,
          align: "center",
          filters: [
            {
              label: ">2017",
              value: 1
            },
            {
              label: "≤2017",
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return row.score > 2017;
            } else if (value === 2) {
              return row.score < 2017;
            }
          }
        },
        {
          title: "下次安评",
          render: (h, p) => {
            return h(
              "span",
              {
                attrs: {}
              },
              p.row.next_evaluate_time.split("-")[0]
            );
          },
          sortable: true,
          width: 120,
          align: "center",
          filters: [
            {
              label: ">2017",
              value: 1
            },
            {
              label: "≤2017",
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return row.score > 2017;
            } else if (value === 2) {
              return row.score < 2017;
            }
          }
        },
        {
          title: "评价周期",
          render: (h, p) => {
            return h(
              "span",
              {
                attrs: {}
              },
              parseInt(p.row.next_evaluate_time.split("-")[0]) -
                parseInt(p.row.evaluate_time.split("-")[0])+'年'
            );
          },
          align: "center",
          width: 85
        },
        {
          title: "委托方",
          key: "customer",
          align: "center",
          width: 150
        },
        {
          title: "受托方",
          key: "worker",
          align: "center"
        },
        {
          title: "报告类型",
          key: "report_type",
          align: "center",
          width: 150
        },

        {
          title: "报告状态",
          key: "report_stat",
          width: 85,
          align: "center"
        },
        {
          title: "项目状态",
          key: "project_stat",
          align: "center",
          tooltip: "true",
          width: 85
        },
        {
          title: "详情",
          key: "des",
          align: "center",
          width: 80,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "dashed",
                    size: "small"
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.linkTo(params.row.line_code);
                    }
                  }
                },
                "查看"
              )
            ]);
          }
        }
      ]
    };
  },
  methods: {
    linkTo(value) {
      location.href = "/conclusion/report_c/" + value;
    },
    chartRefresh(){
        this.updateCount++;
    },
  }
};
</script>
<style scoped>
</style>