<template>
    <div class="outer-wrapper">
        <div class="second-wrapper">
            <label class="the-label">{{name}}：</label>
            <div class="item-wrapper">
                <span v-for="one in choices" :key="one.id">
                    <button class="ele-choice-disable" v-if="one.stat===0"   :key="one.id" >{{one.text}}</button>
                    <button class="ele-choice" v-else :class="show_style(one)" @click="choose_this(one)" :key="one.id" :style="{color:one.line_color}">{{one.text}}</button>
                </span>
            </div>

        </div>

    </div>

</template>
<script>
    let array_equals = function (array1, array2) {
    if (!array2 || !array1)
        return false;
    if (array1.length != array2.length)
        return false;

    for (var i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!array_equals(array1[i], array2[i]))
                return false;
        }
        else if (array1[i] != array2[i]) {
            return false;
        }
    }
    return true;
}

let object_equals = function (object1, object2) {
    for (let propName in object1) {
        if (Object.prototype.hasOwnProperty.call(object1,propName) != Object.prototype.hasOwnProperty.call(object2,propName)) {
            return false;
        }
        else if (typeof object1[propName] != typeof object2[propName]) {
            return false;
        }
    }
    for (let propName in object2) {
        if (Object.prototype.hasOwnProperty.call(object1,propName) != Object.prototype.hasOwnProperty.call(object2,propName)) {
            return false;
        }
        else if (typeof object1[propName] != typeof object2[propName]) {
            return false;
        }
        if (!Object.prototype.hasOwnProperty.call(object1,propName))
            continue;
        if (object1[propName] instanceof Array && object2[propName] instanceof Array) {
            if (!array_equals(object1[propName], object2[propName]))
                return false;
        }
        else if (object1[propName] instanceof Object && object2[propName] instanceof Object) {
            if (!object_equals(object1[propName], object2[propName]))
                return false;
        }
        else if (object1[propName] != object2[propName]) {
            return false;
        }
    }
    return true;
}

Array.prototype.indexOfObject = function (item) {
    for (let key in this) {
        if (object_equals(this[key], item)) {
            return key;
        }
    }
    return -1;
}
Object.defineProperty(Array.prototype, "indexOfObject", { enumerable: false });

    export default {
        name: "choiceBox",
        props: {
            "main_title": {
                type: String,
                required: true,
            },
            "selist": {
                type: Array,
            },
            "choices": {
                type: Array,
            },
            "key_name":{
                type:String,
                required:true
            },
            "secity":{
                type:String,
                default:""
            }
        },
        methods: {
            choose_this(value) {
                if(value.city_stat!==undefined){
                    value.new_text = value.city_stat + value.text;
                }else{
                    value.new_text = value.text;
                }
                let temp = { "category_t": this.name, "value": value.value,'text':value.new_text,'key_name':this.key_name };
                if(this.key_name==="city"){
                    this.$emit("ch_city",value.value);
                }else{
                this.$emit("click_f", temp);
                }
            },
            show_style(value) {
                if(value.city_stat!==undefined){
                    value.new_text = value.city_stat + value.text;
                }else{
                    value.new_text = value.text;
                }
                let temp = { "category_t": this.name, "value": value.value,'text':value.new_text,'key_name':this.key_name };
                if (this.selist.indexOfObject(temp) !== -1 && this.selist.length !== 0) {
                        return "ele-choice-ed";
                } else if(this.key_name==="city"&&this.secity===value.value){
                        return "ele-choice-ed-city";
                }else{
                        return "";
                }
            },
        },
        created() {
            if(this.key_name==='line_id'){
                this.choices.sort(function(a,b){return parseInt(a.value.split('-')[1])-parseInt(b.value.split('-')[1])});
            }
        },

        computed: {
        },
        data() {
            return {
                name: this.main_title,
                choose_list: [],
                bar_state:'',
                disable_c:"disable_style"
            }
        }
    }
</script>
<style scoped>

    .second-wrapper {
        padding: 10px 0;
        margin: 0 20px;
        border-bottom: 1px dashed #dedede;
        min-height: 48px;
        position: relative;
    }

    .sub_bar_wrapper{
        position: absolute;
        z-index: 9999;
        top: 59px;
        left: 100px;
    }

    .the-label {
        display: block;
        color: #999;
        font-size: 14px;
        float: left;
        height: 28px;
        line-height: 28px;
        min-width: 100px;
    }

    .ele-choice {
        color: #666;
        font-size: 15px;
        padding: 2px 14px;
        margin: 0 5px;
        border: none;
        background: #fff;
        border: 1px dotted #fff;
        border-radius: 2px;
        cursor: pointer;
        min-width: 80px;
    }

    .ele-choice-disable {
        color: rgb(179, 177, 177);
        font-size: 15px;
        padding: 2px 14px;
        margin: 0 5px;
        border: none;
        background: #fff;
        border: 1px dotted #fff;
        border-radius: 2px;
        cursor:text;
    }

    .ele-choice:hover {
        border: 1px solid #b4a078;
        text-shadow: 1px 0 1px #eee, 0 1px 1px #eee, 0 -1px 1px #777, -1px 0 1px #777;
        color: #000;
    }

    .ele-choice-ed {
        background: #b4a078;
        color: #fff;
    }

    .ele-choice-ed-city {
        background: #606aa1;
        color: #fff;
    }

    .item-wrapper {
        padding: 0;
        margin-left:100px;
    }
    .disable_style{
        cursor: not-allowed;
        color:#f7f7f7;
    }
</style>